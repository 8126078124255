body {
  text-align: center;
  background-size: cover;
  background-repeat: repeat-y;
  background-image: url("https://legendoftheblackhole-storage-external171217-dev.s3.us-east-2.amazonaws.com/background/20240311_212744.jpg");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
   font-size: 3em;
   color: white;
   font-family: "Mountains of Christmas", serif;
   text-align: center;
   margin-bottom: 0px;
}

.fb-like {
  font-size: 2em;
  background-color: #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.btn {
  background-color: #22262c;
  border-color: #333;
  min-width: 5rem;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #fff;
  background-color: #fafbfc;
  border-radius: .25rem;
  border: 1px solid #fff;
  background-color: #7f7d80;
  margin: 0px 0px 10px 0px;
}

.btn:hover {
  color: #111;
  background-color: #d198e3;
  border: 1px solid #c6c1c7;
}

.clap {
  background-color: #ddd;
  border-radius: 5px;
  float: right;
  margin: 10px 70px 10px 0px;
  padding-right: 10px;
}

.clap:hover {
  background-color: #d198e3;
}